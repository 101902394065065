import { ReactElement } from "react";
import { Calendar, Leader } from "../../../store";
import { Box, Divider, Grid, Skeleton, Typography, styled } from "@mui/material";
import CabAvatar from "@CabComponents/CabAvatar";
import { CabButton } from "@CabComponents/CabButton";
import { CabIcon } from "@CabComponents/CabIcon";
import colors from "../../../colors";
import CabCollapseMenu from "@CabComponents/CabCollapseMenu";
import { CabToggleChip } from "@CabComponents/CabToggleChip";
import { IoAdd, IoCreateOutline, IoTrashOutline, IoEllipsisVertical } from "react-icons/io5";


export interface ExecutiveCalendarsProps {
  leader: Leader;
  leaderCalendars: Calendar[];
  openAddEditModal: (leaderId: number, calendarId?: number) => void;
  removeCalendar: (leaderId: number, calendarId: number) => void;
  loading: boolean;
  hasCrmFeature?: boolean;
}

export const ExecutiveCalendars = ({
  leader, leaderCalendars, openAddEditModal, removeCalendar, loading, hasCrmFeature
}: ExecutiveCalendarsProps): ReactElement => {
  const sharedBy = leader.shared_by ? `${leader.shared_by.first_name} ${leader.shared_by.last_name}` : undefined;

  return (
    <>
      <StyledBox>
        <Box display='flex' justifyContent='space-between' marginBottom={1}>
          <Box display='flex' gap={1}>
            <CabAvatar
              color={leader.color}
              isShared={leader.is_shared}
              sharedBy={sharedBy}
              size="medium"
              src={leader.pic_url}
              name={`${leader.first_name} ${leader.last_name}`}
            />
            <Typography variant="h4">{`${leader.first_name} ${leader.last_name}`}</Typography>
          </Box>
          <CabButton
            buttonType='text'
            color="primary"
            onClick={() => openAddEditModal(leader.id)}
            sx={{ height: 30, marginRight: 1.5 }}
            icon={<CabIcon Icon={IoAdd} alt='Add' />}
          >
            Add Calendar
          </CabButton>
        </Box>
        {!loading ? (
          <>
            {leaderCalendars.map((calendar, idx) => {
              return (
                <Box key={`${calendar.calendar_id} ${idx}`}>
                  {idx > 0 && (
                    <Divider sx={{width: "100%"}}/>
                  )}
                  <Grid container paddingTop={1.5} paddingBottom={1.5} justifyContent='space-between' 
                    display='flex' alignItems='center' gap={1}>
                    <Grid item xs={12}>
                      <Typography variant="body1" sx={{overflowWrap: 'none'}}>
                        {calendar?.additionalCalendarEmail ? 
                          calendar?.additionalCalendarEmail.name : (calendar.summary || calendar.calendar_id)}
                      </Typography>
                    </Grid>
                    <Grid item xs={11} display='flex' gap={1} alignItems='center' flexWrap={'wrap'}>
                      {leader.leader_calendars[calendar.id] 
                      && leader.leader_calendars[calendar.id].prevent_conflict && (
                        <CabToggleChip
                          label='Checked for Conflicts'
                          title={`Check this calendar for conflicts when allowing others to book meetings via ` + 
                            `booking links if "Prevent double-booking" is turned on.` }
                          sx={{ 
                            backgroundColor: colors.black200, borderColor: colors.black200, fontSize: 12
                          }}
                        />
                      )}
                      {leader.leader_calendars[calendar.id] && 
                        leader.leader_calendars[calendar.id].allow_calendar_analytics && (
                        <CabToggleChip
                          label='Included in Analytics'
                          title={`Include this calendar in analytics calculations for calendar auditing.`}
                          sx={{ 
                            backgroundColor: colors.black200, borderColor: colors.black200, fontSize: 12
                          }}
                        />
                      )}
                      {hasCrmFeature && leader.leader_calendars[calendar.id] && 
                        leader.leader_calendars[calendar.id].allow_crm && (
                        <CabToggleChip
                          label='Collect Contacts'
                          title={`Automatically add contacts to Connections from attendees on this calendar's events.`}
                          sx={{ 
                            backgroundColor: colors.black200, borderColor: colors.black200, fontSize: 12
                          }}
                        />
                      )}
                    </Grid>
                    <Grid item>
                      <CabCollapseMenu 
                        buttons={<>
                          <StyledGroupButton
                            buttonType="text"
                            onClick={() => openAddEditModal(leader.id, calendar.id)} 
                            icon={<CabIcon Icon={IoCreateOutline} color={colors.black900}/>}
                          >
                            Edit Calendar
                          </StyledGroupButton>
                          <StyledGroupButton
                            buttonType="text"
                            onClick={() => removeCalendar(leader.id, calendar.id)} 
                            icon={<CabIcon Icon={IoTrashOutline} color={colors.black900}/>}
                          >
                            Remove Calendar
                          </StyledGroupButton>
                        </>}
                        popOverTitle=""
                        target={<CabIcon
                          Icon={IoEllipsisVertical} alt={`Edit ${calendar.additionalCalendarEmail ? 
                            calendar.additionalCalendarEmail.name : (calendar.summary || calendar.calendar_id)}`}
                          sx={{marginRight: 0, color: colors.black800, fontSize: 18}}
                        />}
                        buttonGroupColor="inherit"
                        buttonGroupVariant="outlined"
                        buttonGroupSx={{paddingTop: 1, paddingBottom: 1}}
                      />
                    </Grid>
                  </Grid>
                </Box>
              );
            })}
          </>
        ) : (
          <Skeleton height={50} width={'100%'} />
        )}
      </StyledBox>
    </>
  );
};

const StyledBox = styled(Box, {label: "StyledBox"})(() => ({
  minWidth: 450,
  border: '1px solid',
  borderColor: colors.black200,
  borderRadius: 6,
  padding: 16,
  marginRight: 8
}));

const StyledGroupButton = styled(CabButton, {label: "StyledGroupButton"})({
  justifyContent: 'start', 
  color: colors.black900, 
  paddingBottom: 8, 
  paddingTop: 8, 
  paddingLeft: 15, 
  paddingRight: 15,
  width: '100%'
});
