import { Box, Skeleton, Typography, styled } from "@mui/material";
import CabinetPage from "../../components/Common/CabinetPage";
import { Calendar, Leader } from "../../store";
import { CabButton } from "@CabComponents/CabButton";
import colors from "../../colors";
import { ExecutiveCalendars } from "../../components/ManageCalendars/ExecutiveCalendars";
import { MissingCalendars } from "../../components/ManageCalendars/MissingCalendars";
import { isMobile } from "../../utils/screenSizeUtils";
import { CabIcon } from "@CabComponents/CabIcon";
import { IoCalendarOutline } from 'react-icons/io5';

interface CalendarsProps {
  calendars: Calendar[];
  leaders: Leader[];
  openAddEditModal: (leaderId: number, calendarId?: number) => void;
  showMissing: boolean;
  removeCalendar: (leaderId: number, calendarId: number) => void;
  handleSetAdditionalCalendars: (value: { [eml: string]: Calendar[] }) => void
  additionalCalendars: { [eml: string]: Calendar[] }
  onGetEmailCalendars: (email: string) => Promise<Calendar[]>
  additionalDelegateEmails: (string | null)[];
  loading: boolean;
  leadersLoaded: boolean;
  hasCrmFeature?: boolean;
}

const Calendars = ({
  calendars, leaders, openAddEditModal, showMissing,
  removeCalendar, handleSetAdditionalCalendars, additionalCalendars, onGetEmailCalendars, 
  additionalDelegateEmails, loading, leadersLoaded, hasCrmFeature
}: CalendarsProps) => {

  return (
    <Box padding={4} display='flex' flexDirection='column' gap={2} width='100%'>
      <Box display='flex' flexDirection='column' gap={.5} maxWidth={700}>
        <Typography variant='body1' color={colors.black700}>
          Choose the calendars you'd like to have visible for each executive when scheduling meetings
        </Typography>
      </Box>
      <Box display="flex" gap={3} justifyItems={'flex-start'} flexDirection={{ xs: 'column', lg: 'row' }}>
        <Box display="flex" flex={1} flexDirection={'column'} gap={2} maxWidth={!showMissing ? 1000 : 'unset'}>
          {leadersLoaded ? (
            leaders.filter(l => l.permissions.scheduling.edit || l.permissions.scheduling.add_to_meeting)
              .map(leader => (
                <Box key={leader.id}> 
                  <ExecutiveCalendars
                    leader={leader}
                    leaderCalendars={calendars.filter(c => c.leaders.includes(leader.id))}
                    openAddEditModal={openAddEditModal}
                    removeCalendar={removeCalendar}
                    loading={loading}
                    hasCrmFeature={hasCrmFeature}
                  />
                </Box>
              ))
          ) : (
            <SkeletonLeaderCalendar />
          )}
        </Box>
        {showMissing && (
          <Box>
            <MissingCalendars
              handleSetAdditionalCalendars={handleSetAdditionalCalendars}
              onGetEmailCalendars={onGetEmailCalendars}
              additionalCalendars={additionalCalendars}
              additionalDelegateEmails={additionalDelegateEmails}
              loading={loading}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};
interface ManageCalendarsProps {
  calendars: Calendar[];
  leaders: Leader[];
  onManageAccountsClick: () => void;
  openAddEditModal: (leaderId: number, calendarId?: number) => void;
  showMissing: boolean;
  removeCalendar: (leaderId: number, calendarId: number) => void;
  handleSetAdditionalCalendars: (value: { [eml: string]: Calendar[] }) => void
  additionalCalendars: { [eml: string]: Calendar[] }
  onGetEmailCalendars: (email: string) => Promise<Calendar[]>
  hasGrant: boolean;
  additionalDelegateEmails: (string | null)[];
  loading: boolean;
  leadersLoaded: boolean;
  hasCrmFeature?: boolean;
}

const ManageCalendars = ({
  calendars, onManageAccountsClick, leaders, openAddEditModal, showMissing, removeCalendar, 
  handleSetAdditionalCalendars, additionalCalendars, onGetEmailCalendars, hasGrant, 
  additionalDelegateEmails, loading, leadersLoaded, hasCrmFeature
}: ManageCalendarsProps) => {

  return (
    <CabinetPage
      pageName={'Manage Calendars'}
      headerContent={
        <Box display="flex" flexDirection="row" justifyContent="space-between" width="100%">
          <Typography variant="h1">
            Calendar Settings
          </Typography>
          {!isMobile() && (
            <CabButton 
              onClick={onManageAccountsClick}
            >
              Manage Integrations
            </CabButton>
          )}
        </Box>
      }
    >
      {hasGrant ? (
        <Calendars
          calendars={calendars}
          leaders={leaders}
          openAddEditModal={openAddEditModal}
          showMissing={showMissing}
          removeCalendar={removeCalendar}
          handleSetAdditionalCalendars={handleSetAdditionalCalendars}
          additionalCalendars={additionalCalendars}
          onGetEmailCalendars={onGetEmailCalendars}
          additionalDelegateEmails={additionalDelegateEmails}
          loading={loading}
          leadersLoaded={leadersLoaded}
          hasCrmFeature={hasCrmFeature}
        />
      ) : (
        <MessageContainer>
          <CabIcon Icon={IoCalendarOutline} sx={{ fontSize: 60 }} />
          <Typography variant="body1" sx={{margin: "24px 0"}}>
            You're not currently signed into any calendar provider.
            Sign in so we can help you schedule meetings faster!
          </Typography>
          <CabButton buttonType="tertiary" color="primary" onClick={onManageAccountsClick}>
            Connect Your Calendars
          </CabButton>
        </MessageContainer>
      )}
    </CabinetPage>
  );
};

export default ManageCalendars;

const MessageContainer = styled("span", {label: "MessageContainer"})({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: "42px",
});

const StyledBox = styled(Box, {label: "StyledBox"})(() => ({
  maxWidth: 900,
  minWidth: 500,
  border: '1px solid',
  borderColor: colors.black200,
  borderRadius: 6,
  padding: 16,
  marginRight: 8
}));

const SkeletonLeaderCalendar = () => {
  return (
    <StyledBox>
      <Box display='flex' justifyContent='space-between' marginBottom={1}>
        <Box display='flex' gap={1}>
          <Skeleton variant='circular' width={28} height={28} />
          <Skeleton width={200} height={30} />
        </Box>
        <Skeleton width={100} height={30} />
      </Box>
      <Box paddingTop={1.5} paddingBottom={1.5} justifyContent='space-between' 
        display='flex' alignItems='center' gap={1}>
        <Skeleton height={50} width={'100%'} />
      </Box>
    </StyledBox>
  );
};