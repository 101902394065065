import { ReactElement, useEffect, useMemo, useState } from "react";
import { Calendar, Leader } from "../../../store";
import { CabModal } from "@CabComponents/CabModal";
import { CabButton } from "@CabComponents/CabButton";
import { Box, FormLabel, Typography, styled } from "@mui/material";
import CabAvatar from "@CabComponents/CabAvatar";
import { CabIcon } from "@CabComponents/CabIcon";
import { IoWarning } from 'react-icons/io5';
import { nonReadDisableText } from "../../../utils/scheduleUtils";
import { CabAutocomplete } from "@CabComponents/CabAutocomplete";
import { CabSwitch } from "@CabComponents/CabSwitch";
import { uniqBy } from "lodash-es";
import { CirclePicker } from "react-color";
import { GOOGLE_COLORS, MICROSOFT_COLORS, PROVIDER } from "../../../constants";
import { CabToggleChip } from "@CabComponents/CabToggleChip";


export interface AddEditCalendarModalProps {
  open: boolean;
  calendars: Calendar[];
  leader: Leader;
  onClose: () => void;
  calendarId?: number;
  onAddEditCalendar: (calendarId: number, conflicts: boolean, analytics: boolean, crm: boolean, color: string) => void;
  hasCrmFeature?: boolean;
}

export const AddEditCalendarModal = ({
  open, calendars, calendarId, leader, onClose, onAddEditCalendar, hasCrmFeature
}: AddEditCalendarModalProps): ReactElement => {
  const [calId, setCalId] = useState(calendarId);
  const [calendarColorPickerOpen, setCalendarColorPickerOpen] = useState(false);
  const calendar = useMemo(
    () => calendars.find((cal) => cal.id === calId ),
    [calendars, calId]
  );

  const [checkConflicts, setCheckConflicts] = useState<boolean>(calId && 
    leader.leader_calendars[calId]?.prevent_conflict !== undefined ? 
    leader.leader_calendars[calId].prevent_conflict : true);
  const [includeAnalytics, setIncludeAnalytics] = useState<boolean>(calId && 
    leader.leader_calendars[calId]?.allow_calendar_analytics !== undefined ? 
    leader.leader_calendars[calId].allow_calendar_analytics : true);
  const [allowCrm, setAllowCrm] = useState<boolean>(calId && 
    leader.leader_calendars[calId]?.allow_crm !== undefined ? 
    leader.leader_calendars[calId].allow_crm : true);
  
  const [calendarColor, setCalendarColor] = useState<string>(calendar ? 
    (calendar.overrideBackgroundColor || calendar.autoBackgroundColor) : "#FF0000");

  const calendarOptions = calendars.map((calendarItr) => ({
    value: calendarItr.id,
    icon: (!calendarItr.canEdit && !calendarItr.readable) ? <CabIcon 
      Icon={IoWarning} 
      color="white"
      alt={nonReadDisableText(calendarItr.canEdit, calendarItr.readable, calendarItr.provider, true)}
    /> : 
      undefined,
    label: calendarItr.additionalCalendarEmail ? calendarItr.additionalCalendarEmail.name : calendarItr.summary,
    color: calendarItr.backgroundColor,
    tooltipTitle: nonReadDisableText(calendarItr.canEdit, calendarItr.readable, calendarItr.provider, true)
  }));

  const uniqueCalendarOptions = uniqBy(calendarOptions, cal => cal.value)
    .sort((a, b) => { // Sort alphabetically
      const calA = a.label.toUpperCase();
      const calB = b.label.toUpperCase();
      return calA < calB ? -1 : calA > calB ? 1 : 0;
    });

  useEffect(() => {
    setCheckConflicts(calId && leader.leader_calendars[calId]?.prevent_conflict !== undefined 
      ? leader.leader_calendars[calId].prevent_conflict : true);
    setIncludeAnalytics(calId && leader.leader_calendars[calId]?.allow_calendar_analytics !== undefined
      ? leader.leader_calendars[calId].allow_calendar_analytics : true);
    setAllowCrm(calId && leader.leader_calendars[calId]?.allow_crm !== undefined
      ? leader.leader_calendars[calId].allow_crm : true);
    setCalendarColor(calendar ? 
      (calendar.overrideBackgroundColor || calendar.autoBackgroundColor) : "#FF0000");
  }, [calendar, calId, leader.leader_calendars]);

  const handleCalendarColor = (color: string) => {
    setCalendarColor(color);
    setCalendarColorPickerOpen(false);
  };

  const colorSet = useMemo(() => {
    if (calendar?.provider === PROVIDER["GOOGLE"].id) {
      return Object.values(GOOGLE_COLORS["calendar"]).map(color => color.background);
    } else if (calendar?.provider === PROVIDER["MICROSOFT"].id) {
      return Array.from(Object.values(MICROSOFT_COLORS));
    }
  }, [calendar]);

  return (
    <>
      <CabModal
        open={open}
        title={calendarId ? 'Edit Calendar' : 'Add Calendar'}
        onClose={onClose}
        closeIcon
        sx={{
          '& .MuiPaper-root': {
            minWidth: 570,
          },
        }}
        actionButtons={
          <>
            <CabButton buttonType='tertiary' color='primary' onClick={onClose}>
              Cancel
            </CabButton>
            <CabButton onClick={calId ? () => onAddEditCalendar(
              calId, checkConflicts, includeAnalytics, allowCrm, calendarColor
            ) : undefined} 
            disabled={!calId}
            >
              {calendarId ? 'Save Changes' : 'Add Calendar'}
            </CabButton>
          </>
        }
      >
        <Box display='flex' flexDirection='column' gap={2}>
          <Typography variant="body1" fontWeight={700}>Executive Calendar</Typography>
          <Box display='flex'>
            <CabAvatar
              color={leader.color}
              size="small"
              src={leader.pic_url}
              name={`${leader.first_name} ${leader.last_name}`}
              sx={{ marginRight: 1 }}
            />
            <Typography variant="h5">{`${leader.first_name} ${leader.last_name}`}</Typography>
          </Box>
          <CabAutocomplete<number, never>
            value={calId  || null}
            onChange={(v) => setCalId(Number(v))}
            placeholder={calId ? undefined : 'Select a calendar'}
            sx={{ width: 'auto' }}
            options={uniqueCalendarOptions}
          />
          <Typography variant="body1" fontWeight={700}>Calendar Settings</Typography>
          <Box>
            <AdditionalOption>
              <FormLabel> Check calendar for conflicts </FormLabel>
              <CabSwitch checked={checkConflicts} onChange={() => setCheckConflicts(!checkConflicts)} />
            </AdditionalOption>
            <AdditionalOption>
              <FormLabel> Include calendar in analytics </FormLabel>
              <CabSwitch checked={includeAnalytics} onChange={() => setIncludeAnalytics(!includeAnalytics)} />
            </AdditionalOption>
            {hasCrmFeature &&
              <AdditionalOption>
                <FormLabel> Collect contacts from events </FormLabel>
                <CabSwitch checked={allowCrm} onChange={() => setAllowCrm(!allowCrm)} />
              </AdditionalOption>
            }
            <AdditionalOption>
              <FormLabel> Override calendar color </FormLabel>

              {calendarColor && <Box sx={{padding: 0.5}}><CabToggleChip
                chipColor={calendarColor}
                selected={true}
                sx={{
                  width: 40,
                  fontStyle: "italic",
                  cursor: "pointer"
                }}
                onClick={() => setCalendarColorPickerOpen(true)}
              /></Box>}
            </AdditionalOption>
          </Box>
        </Box>
      </CabModal>
      <CabModal
        open={calendarColorPickerOpen}
        actionButtons={<></>}
        onClose={() => setCalendarColorPickerOpen(false)}
        closeIcon
        title="Choose Calendar Color"
      >
        <CirclePicker 
          width='100%'
          circleSpacing={8}
          circleSize={32}
          colors={colorSet} 
          color={calendarColor}
          onChange={(color): void => handleCalendarColor(color.hex)}
        />
      </CabModal>
    </>
  );
};

const AdditionalOption = styled(FormLabel, { label: 'AdditionalOption' })(() => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  justifyContent: 'space-between',
}));